import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import type { FileWithPath } from 'react-dropzone';

import {
  BackToButton,
  EnhancedAlert,
  SaveButton,
  UploadDropzone,
} from 'components/shared';
import { convertAxiosErrorToYupError } from 'utils/convertAxiosErrorToYupError';
import {
  useCreateOrganizationsUpload,
  useCreateOrganizationsPresignedUrl,
} from 'hooks/api/district';
import { useGetDistrictById } from 'gql/queries/districts';

// Component Definition
const ImportOrganizations = (): JSX.Element => {
  const navigate = useNavigate();
  const { districtId } = useParams();

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [s3GetUrl, sets3GetUrl] = useState<string | null>(null);
  const [s3PutUrl, sets3PutUrl] = useState<string | null>(null);
  const [isUploadingToS3, setIsUploadingToS3] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [showAddFileMessage, setShowAddFileMessage] = useState(false);

  const { data, loading } = useGetDistrictById(districtId);

  const districtShowPagePath = `/districts/${districtId}`;

  const handleGoToDistrictShowPage = useCallback(() => {
    navigate(districtShowPagePath);
  }, [navigate, districtShowPagePath]);

  const {
    data: presignedUrlData,
    error: presignedUrlError,
    isLoading: isGeneratingPresignedUrl,
    mutate: createOrganizationsPresignedUrl,
  } = useCreateOrganizationsPresignedUrl();

  const {
    error: createOrganizationsUploadError,
    isLoading: isCreatingOrganizationsUpload,
    mutate: createOrganizationsUpload,
  } = useCreateOrganizationsUpload();

  // When the presignedUrlData changes, set the local state with the S3 URLs
  useEffect(() => {
    if (presignedUrlData) {
      sets3GetUrl(presignedUrlData.data?.data?.getUrl);
      sets3PutUrl(presignedUrlData.data?.data?.putUrl);
    }
  }, [presignedUrlData]);

  // The user presses the CTA button that reads "Upload Organizations"
  // This will send the file to S3 and update the Presto database with the S3 key
  const handleUploadToS3 = useCallback(async () => {
    if (!s3PutUrl) {
      return setHasError(true);
    }

    setIsUploadingToS3(true);

    try {
      const res = await fetch(s3PutUrl, {
        body: csvFile,
        method: 'PUT',
      });

      if (!res.ok) {
        throw new Error('Unable to upload csv file.');
      }

      if (hasError) {
        setHasError(false);
      }

      setIsUploadingToS3(false);

      // Call endpoint to import the CSV file
      return createOrganizationsUpload({
        body: {
          s3Url: s3GetUrl!,
        },
        params: {
          districtId: districtId!,
        },
      });
    } catch (error) {
      return setHasError(true);
    }
  }, [createOrganizationsUpload, csvFile, districtId, hasError, s3GetUrl, s3PutUrl]);

  const yupErrors = useMemo(() => {
    if (presignedUrlError) {
      const yupCreatePresignedUrlError = convertAxiosErrorToYupError(
        presignedUrlError as AxiosError,
      );

      return yupCreatePresignedUrlError ? Object.values(yupCreatePresignedUrlError) : null;
    }

    return null;
  }, [presignedUrlError]);

  const handleUploadFile = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];

    setCsvFile(file);

    try {
      if (hasError) {
        setHasError(false);
      }

      return createOrganizationsPresignedUrl({
        body: {
          filename: file.name,
          filetype: file.type,
        },
        params: {
          districtId: districtId!,
        },
      });
    } catch (err) {
      setCsvFile(null);
      return setHasError(true);
    }
  }, [createOrganizationsPresignedUrl, districtId, hasError]);

  const handlePressUploadDropzoneContainer = useCallback(() => {
    if (showAddFileMessage) {
      return setShowAddFileMessage(false);
    }

    return null;
  }, [showAddFileMessage]);

  const handlePressUploadButton = useCallback(() => {
    if (!csvFile) {
      setShowAddFileMessage(true);
    } else {
      handleUploadToS3();
    }
  }, [csvFile, handleUploadToS3]);

  const isLoading = isGeneratingPresignedUrl || isUploadingToS3 || isCreatingOrganizationsUpload;

  return (
    <Container fixed>
      <Box marginBottom={4}>
        <BackToButton
          context="District Detail"
          navigateTo={districtShowPagePath}
        />
      </Box>

      <Stack
        direction="column"
        spacing={2}
      >
        <Card
          sx={{
            borderRadius: '12px',
          }}
          variant="outlined"
        >
          <Typography
            component="h1"
            gutterBottom
            sx={{
              marginLeft: 2,
              marginTop: 2,
            }}
            variant="h5"
          >
            Import Organizations
          </Typography>

          <CardContent
            sx={{
              padding: 2,
            }}
          >
            {
              loading
                ? <CircularProgress />
                : (
                  <Typography
                    gutterBottom
                    variant="h6"
                  >
                    {data?.districtById.label}
                  </Typography>
                )
            }

            <div
              onClick={handlePressUploadDropzoneContainer}
              role="presentation"
              tabIndex={-1}
            >
              <UploadDropzone
                disabled={isLoading}
                helperText={`Upload a CSV file with ${data?.districtById.label} organization info`}
                marginTop={1}
                multiple={false}
                onDrop={handleUploadFile}
                rejectedDropErrorMessage="Unable to upload files"
                showAcceptedFiles
              />
            </div>

            {yupErrors?.map((errorMessage) => (
              <EnhancedAlert
                key={errorMessage as string}
                severity="error"
                sx={{ marginTop: 2 }}
              >
                {errorMessage}
              </EnhancedAlert>
            ))}

            <Collapse
              in={Boolean(
                (createOrganizationsUploadError as AxiosError)?.response?.data.error ?? null,
              )}
            >
              <EnhancedAlert
                severity="error"
                sx={{ marginTop: 2 }}
                title="Something went sideways"
              >
                {(createOrganizationsUploadError as AxiosError)?.response?.data.error ?? null}
              </EnhancedAlert>
            </Collapse>

            <Collapse in={hasError}>
              <EnhancedAlert
                severity="error"
                sx={{ marginTop: 2 }}
                title="Something went sideways"
              >
                Please try again or refresh the page.
              </EnhancedAlert>
            </Collapse>

            <Collapse in={showAddFileMessage}>
              <EnhancedAlert
                severity="info"
                sx={{ marginTop: 2 }}
                title="No file added"
              >
                Press the dashed box above or drop a file there.
              </EnhancedAlert>
            </Collapse>
          </CardContent>

          <CardActions
            sx={{
              justifyContent: 'flex-end',
              padding: 2,
            }}
          >
            <Button
              onClick={handleGoToDistrictShowPage}
              startIcon={<ArrowBackIcon />}
              sx={{
                borderRadius: '20px',
              }}
              variant="outlined"
            >
              Go Back
            </Button>

            <SaveButton
              disabled={!csvFile && showAddFileMessage}
              isSaving={isLoading}
              onClick={handlePressUploadButton}
              startIcon={<CloudUploadIcon />}
              sx={{
                borderRadius: '20px',
              }}
              variant="contained"
            >
              Upload Organizations
            </SaveButton>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  );
};

export default ImportOrganizations;
